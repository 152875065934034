.input {
  width: 100%;
  min-height: 2.5em;
  text-align: left;
  box-sizing: border-box;
  outline: 0;
  padding: 0.5rem;
  font-size: 1rem;
  background: none;
  color: #FFF;
  border: none;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 10px;
  z-index: 1;
  font-family: "Proxima Nova Bold";

  &__label {
    position: absolute;
    color: #FFF;
    // top: .5rem;
    // left: 0rem;
    z-index: 0;
  }

  &__transparent-text {
    color: transparent;
    margin-right: 1.2rem;
  }

  &_type {
    &_textarea {
      width: calc(100% - 15px);
      height: 100%;
      font-size: 12px;
      color: #6b7a99;
      background-color: #0f1624;
      border-radius: 10px;
      padding: 22px 15px;
      border: none;
      resize: none;
      font-family: "Proxima Nova Bold";
      &::placeholder {
        color: #656c82;
      }
    }
  }

  &_unBorder {
    border: none;
  }

  &_centerText {
    text-align: center;
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(1)
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
