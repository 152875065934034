.rules {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 8000;
  box-sizing: border-box;
  background-color: rgba(20, 25, 38, .82);
  animation: rulesAnimation .2s linear forwards;

  &__bg {
    width: 100%;
    min-height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__wrapper {
    cursor: default;
    padding: 15px;
    background-color: #1f2533;
    border-radius: 5px;
    position: relative;
    color: #97a1c2;
    width: 70%;
  }

  &__content {
    padding: 10px 30px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__close {
    cursor: pointer;
  }
}

@media (max-width: 767.98px) {
 .rules {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: auto;
    &__wrapper {
      padding: 0;
      width: 100%;
    }
    &__content {
      padding: 2rem 1rem;
      box-sizing: border-box;
    }
    .popup-rule-bullets {
      flex-direction: column;
    }
    .popup-rule-bullets-left,
    .popup-rule-bullets-right {
      width: 100%;
    }
  }
}

.popup-rules-title-1 {
  text-align: center;
  color: #e2e8fb;
  font-size: 20px;
  padding: 0 20%;
}

.popup-rules-title-2 {
  text-align: center;
  color: #e2e8fb;
  font-size: 16px;
  margin-top: 15px;
  padding: 0 15%;
}

.popup-rules-title-3 {
  text-align: center;
  color: #e2e8fb;
  font-size: 16px;
  margin-top: 15px;
  padding: 0 10%;
}

.popup-rules-title-4 {
  text-align: center;
  color: #bef159;
  font-size: 34px;
  margin-top: 25px;
  padding: 0 20%;
}

.pn-semibold {
  font-family: "Proxima Nova Semibold";
}

.popup-rule-bullets {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.popup-rule-bullets-left,
.popup-rule-bullets-right {
  width: 45%;
}

.popup-rule-bullet-item {
  position: relative;
  padding-left: 30px;
  font-size: 14px;
  color: #97a1c2;
  margin-bottom: 10px;
  &:not(:empty) {
    &::before {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: #bef159;
      top: 5px;
      left: 10px;
    }
  }
}

.popup-rules-limits-title {
  font-size: 14px;
  color: #e2e8fb;
  margin: 10px 0;
}

.popup-rules-limits-table {
  background-color: #141926;
  border-radius: 5px;
  font-size: 13px;
}

.popup-rules-limits-header {
  display: flex;
}

.popup-rule-limit-header-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 36px;
  color: #d5daec;
  text-align: center;
  border-bottom: 1px solid rgba(151,161,194,.4);
  padding: 0 5px;
  &:not(:last-child) {
    border-right: 1px solid rgba(151,161,194,.4);
  }
}

.popup-rules-limits-body {
  display: flex;
  .popup-rule-limit-body-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 36px;
    color: #bef159;
    text-align: center;
    padding: 0 5px;
    &:not(:last-child) {
      border-right: 1px solid rgba(151,161,194,.4);
    }
  }
}

.popup-rules-rtp-bullet {
  position: relative;
  padding-left: 30px;
  font-size: 14px;
  color: #97a1c2;
  margin: 15px 0;
  &::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: #bef159;
    top: 5px;
    left: 10px;
  }
}

@keyframes rulesAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}