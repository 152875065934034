.subheader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: #1f2533;
  border-radius: 5px;
  border-bottom: 2px solid #0c121e;
  box-sizing: content-box;

  &__info-item {
    display: flex;
    align-items: center;
  }

  &__info-title {
    font-size: 14px;
    padding-left: 25px;
    font-family: "Proxima Nova Semibold";
    color: #97a1c2;
  }

  &__info-value {
    font-size: 14px;
    text-align: right;
    padding-left: 15px;
    color: #ccd6f6;
    span {
      color: #97a1c2;
    }
  }
}

@media (max-width: 575.98px) {
  .subheader {
    background-color: unset;
    border-bottom: none;
    justify-content: flex-end;
    &__info-item {
      align-items: start;
      flex-direction: column;
      margin-left: 15px;
    }
    &__info-title {
      font-size: 9px;
      padding-left: 0;
    }
    &__info-value {
      font-size: 10px;
      padding-right: 0;
      padding-left: 0;
    }
  }
}
