.music-window {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(20,25,38,.73);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;

  &__container {
    background-color: #283042;
    border-radius: 5px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__title {
    color: #e2e8fb;
    font-size: 14px;
  }
  &__buttons {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }
  &__button {
    background-color: #141926;
    border-radius: 5px;
    color: #97a1c2;
    font-size: 14px;
    width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    cursor: pointer;
    user-select: none;
  }
  &__icon {
    margin-left: 5px;
  }
}
