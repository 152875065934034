.messages {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0 20px;
  box-sizing: border-box;
  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-wrap: wrap;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &__nick {
    color: #a1f15b;
    margin-right: 4px;
  }

  &__text {
    color: #dce1f2;
    word-break: break-word;
  }
}