.chat {

}

@media screen and (min-width: 700px) {
  .chat {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;

    &__top-block {
      display: flex;
      width: 100%;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      color: #adb8cc;
      height: 50px;
    }
    &__open-icon {
      cursor: pointer;
    }
    &__ellipse {
      width: .6rem;
      margin-right: .5rem;
    }
    &__switcher {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
    &__arrow {
      margin-left: 0.3rem;
      width: 1.2rem;
      transition: all .5s ease-out;
      &_isOpen {
        transform: rotate(180deg);
      }
    }
    &__bottom-block {
      display: flex;
      width: 100%;
      align-items: center;
      font-weight: 500;
      font-size: 1.1rem;
      color: #ABBBE8;
      margin-top: .8rem;
      padding: 0 .6rem;
      box-sizing: border-box;
    }
    &__input-container {
      height: 60px;
      margin-bottom: 15px;
      position: relative;
    }
    &__send {
      width: 1.8rem;
      margin-left: auto;
      line-height: 0;
      cursor: pointer;
      &_unactive {
        cursor: not-allowed;
        * {
          fill: #3A4768;
        }
      }
    }
    &__open-icon {
      transition: all 1s;
      &_isOpen {
        transform: rotate(180deg);
      }
    }

    &__submit {
      position: absolute;
      height: 50px;
      width: 30px;
      top: 5px;
      right: 15px;
      transform: translateX(15px);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      background-color: #4f566d;
      border-radius: 20px;
      cursor: pointer;
    }
  
    &__messages {
      width: 100%;
      height: 160px;
      margin-bottom: 15px;
      transition: all 1s linear;
      overflow: hidden;
    }
  }
}