.button {
  color: #fff;
  font-weight: 500;
  border: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // padding: 0.5rem 1rem;
  border-radius: 10px;
  outline: none;
  white-space: initial;
  font-family: "Proxima Nova Bold";
  white-space: nowrap;

  &_isRobotoFont {
    font-family: "Roboto-Regular";
  }

  &_isVertical {
    text-orientation: upright;
    writing-mode: vertical-rl;
  }

  &_isBlock {
    // outline: rgba(0, 0, 0, 0.4) solid 20rem;
  }

  &_isDisabled {
    opacity: .5;
    cursor: not-allowed !important;
  }

  &_isUppercase {
    text-transform: uppercase;
  }
  &_isBold {
    font-weight: bold;
  }
  &_fontSize {
    &_small {
      font-size: 14px;
    }
    &_normal {
      font-size: 1rem;
    }
    &_large {
      font-size: 1.2rem;
    }
    &_smaller {
      font-size: 12px;
    }
  }
  &_color {
    &_orange {
      background: #FF8D00;
      border: 1px solid #FF8D00;
    }
    &_dark {
      background: #151b29;
      color: #97a1c2;
    }
    &_red {
      background: #e62b3a;
      border: 1px solid #2A3040;
    }
    &_transparent {
      background: none;
      color: #e2e8fb;
    }
    &_green {
      background: #a1f15b;
      color: #151b29;
    }
    &_yellow {
      background: #f9c70c;
      color: #151b29;
    }
    &_grey {
      background: #283042;
      color: #97a1c2;
    }
  }
  &_size {
    &_container {
      width: 100%;
      height: 100%;
    }
  }
  &__layout {
    &_size {
      &_container {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .button {
    cursor: pointer;
    &_fontSize {
      &_small {
        font-size: 14px;
      }
      &_normal {
        font-size: 1rem;
        line-height: 1rem;
      }
      &_large {
        font-size: 22px;
      }
    }
  }
}