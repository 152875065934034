$player-width: 3rem;
$player-width-mobile: 3.2rem;

.game-view {
  width: 100%;
  height: 100%;
  background: #131926;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  &__shadow {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: calc((100vh - 63px - 40px - 22px - 63px - 210px - 15px) * 1.42);
    &::before {
      content: "";
      position: absolute;
      left: -20%;
      top: -20%;
      width: 140%;
      height: 140%;
      background-image: url(img/chicken_shadow.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
    }
  }
  &__cookies {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  &__bones_chichens_count_conteiner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    width: 165px;
  }
  &__cookies-container {
    position: relative;
    transition: all .3s;
    width: 100%;
    height: calc(100% - 90px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__cookie {
    width: calc(20% - 16px);
    height: calc(20% - 16px);
    margin: 8px;
    padding: 0;
    z-index: 900;
    border-radius: 7px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;  
    filter: brightness(1);
    &:hover {
      filter: brightness(1.2);
    }
    &_isAutoPick {
      box-shadow: 0 0 5px 5px hsl(303, 100%, 57%);
      filter: brightness(100%);
    }
    &_isBlock {
      cursor: not-allowed;
    }
    &_isClosed {
      background-image: url(img/hood.png);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      cursor: pointer;
    }
    &_isEndGame {
      opacity: .55;
    }
  }
  &__icon {
    width: 100%;
    height: 100%;
    animation: 1s animation infinite alternate;
    @keyframes animation {
      0% {
        transform: scale(0.97);
      }
      100% {
        transform: scale(1);
      }
    }
    &_isHide {
      display: none;
    }
  }
  &__small-icon {
    width: 22px;
    height: 22px;
  }
  &__count_container {
    display: flex;
    align-items: center;
    font-size: 15px;
    @media screen and (max-width: 767.98px) {
    width: calc(50% - 10px);
    height: 35px;
    margin: 0 5px;
    padding: 5px 10px;
    }
    
  }
  &__icon_container {
    display:block;
    margin: auto;
  }
  &__count {
    color: #ccd6f6;
  }
  &__separator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    color: #646a80;
  }
  &__good-cookies {
    width: 22%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 37%;
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 700px) {
  .game-view {
    width: calc(18vw * 5);
    height: calc(14.4vw * 5);
    &__cookies-container {
      width: 100%;
      height: 100%;
      position: relative;
      &::after {
        content: '';
        padding-top: 100%;
        float: left;
      }
    }
    &__cookies {
      position: absolute;
    }
    &__winnings-container {
      width: 84%;
    }
    &__arrow-container {
      width: 1.5rem;
    }
    // &__info-cookies {
    //   width: 90%;
    //   height: 2.2rem;
    //   margin-bottom: 1rem;
    //   margin-top: .5rem;
    //   display: flex;
    //   align-items: center;
    //   line-height: 0;
    // }
    &__bad-cookies {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: initial;
      font-size: 1.3rem;
    }
    &__good-cookies {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: initial;
      font-size: 1.3rem;
    }
  }
}

@keyframes lightAnimation {
  80% {
    transform: rotate(0);
  }
  85% {
    transform: rotate(10deg);
  }
  95% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes lightAnimationBottom {
  80% {
    transform: scale(1, -1) rotate(0);
  }
  85% {
    transform: scale(1, -1) rotate(10deg);
  }
  95% {
    transform: scale(1, -1) rotate(-10deg);
  }
  100% {
    transform: scale(1, -1) rotate(0);
  }
}