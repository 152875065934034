.rules-button {
  position: absolute;
  cursor: initial;
  right: 0;
  top: 0;
  max-height: 2.5rem;
  min-width: 2.5rem;
  max-width: 2.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 1.25rem;
  background: #13171D;
  box-sizing: border-box;
  transition: max-width .5s, max-height .5s;
  z-index: 1004;
  &_isOpen {
    width: initial;
    height: initial;
    width: 19.3rem;
    max-width: 19.3rem;
    max-height: 40rem;
    padding-bottom: 0.65rem;
  }
  &__header {
    width: 100%;
    background: #FF8D00;
    padding: .5rem;
    font-weight: 600;
    font-size: 1.1rem;
    white-space: nowrap;
  }
  &__number {
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FF8D00;
    font-size: 1.3rem;
    font-weight: 900;
    border-radius: 100%;
    margin-right: 1rem;
  }
  &__vertical-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  &__image {
    max-width: 12rem;
    max-height: 6rem;
    box-sizing: border-box;
    margin-bottom: .5rem;
  }
  &__icon {
    margin-left: auto;
    margin-bottom: 1.2rem;
    text-align: center;
    cursor: pointer;
    display: block;
    line-height: 0;
    position: relative;
    width: 2.5rem;
    max-height: 2.5rem;
    padding: 0.65rem;
    box-sizing: border-box;
    
    &-svg {
      max-height: 1.2rem;
      * {
        fill: #FFF;
      }
    }
    &_isOpen {
      &-svg {
        max-height: 1.2rem;
        * {
          fill: #FF8D00;
        }
      }
    }
  }
  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.2rem;
    margin-right: .65rem;
    margin-left: .65rem;
    &:nth-child(2) {
      margin-top: .55rem;
    }
  }
  &__text {
    font-size: .9rem;
    max-width: 12rem;
    white-space: initial;
  }
}
