.switch-box {
  width: 50px;
  height: 22px;
  cursor: pointer;
  font-size: 8px;
  background: #242b3b;
  border-radius: 11px;
  color: #646a80;
  position: relative;
  text-align: end;
  padding-right: 6px;
  padding-top: 7px;
  &_active {
    text-align: start;
    padding-left: 6px;
    padding-right: unset;
    color: #eff3ff;
  }

  &__indicator {
    position: absolute;
    width: 14px;
    height: 14px;
    left: 4px;
    top: 4px;
    border-radius: 100%;
    background: #97a1c2;
    margin-left: 0;
    transition: all .2s ease-out;
    &_active {
      left: 32px;
      background: #bef159;
    }
  }
}