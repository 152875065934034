.top-wins {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #97a1c2;
  height: 100%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    background-color: #151b29;
    border-radius: 5px;
    padding: 0 25px;
    font-size: 14px;
    height: 40px;
  }
  &__items {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0 25px;
    font-size: 12px;
    height: calc(100% - 40px);
    color: #a1f15b;
  }
  &__item {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 8px 0;
    font-size: 12px;
    &_isWin {
      color: #a1f15b;
    }
    .bets__column {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: calc(100% - 8px);
      padding: 0 4px;
      padding-right: 12px;
    }
  }
  &__column {
    width: 20%;
    max-width: 20%;
    text-align: center;

    &:first-child {
      text-align: left;
    }
  }
}

@media (max-width: 575.98px) {
  .top-wins {
    &__header {
      padding: 0 10px;
    }
    &__items {
      padding: 0 10px;
    }
  }
}