.loader {
    width: 100%;
    height: 100%;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    &__loader-gif{
        padding-right: 11%;
    }
}