.winnings {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    &__container {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: calc(205px * 2 + 5px);
        height: 43px;
        @media screen and (max-width: 991.98px) {
            width: 100%;
        }
    }
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 205px;
        height: 100%;
        font-size: 12px;
        background-color: #1a2233;
        border-radius: 5px;
        padding: 7px 15px;
        @media screen and (max-width: 991.98px) {
            width: calc(50% - 2.5px);
        }
    }
    &__item-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: space-between;
    }
    &__title {
        color: #646a80;
    }
    &__bottom-block {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    &__coef {
        color: #97a1c2;
    }
    &__amount {
        color: #ccd6f6;
        b {
          color: #97a1c2;
        }
    }
}