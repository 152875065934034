.header {
  width: calc(100% - 465px);
  padding: 10px 20px 0 15px;

  @media (min-width: 600px) and (max-width: 1200px) {
    width: 100%;
  }
  
  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__content {
    height: 53px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  &__title {
    color: #e62b3a;
    font-size: 44px;
    display: flex;
    align-items: center;
    font-family: "Proxima Nova Black";
    padding-left: 15px;
  }

  &__currencies-wrapper {
    @media (min-width: 576px) and (max-width: 1199.98px) {
      width: 130px;
    }
  }

  &__currencies {
    min-width: 200px;
    height: 40px;
    cursor: pointer;
    background-color: #1c2331;
    border-radius: 5px;
    font-weight: normal;
    font-size: 14px;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  }

  &__balance {
    color: #a1f15b;
    margin-right: 10px;
  }

  &__currency {
    color: #97a1c2;
    margin-left: 10px;
  }

  &__currency-arrow {
    width: 24px;
    margin-left: 10px;
    box-sizing: border-box;
    padding: 8px;
    * {
      fill: #97a1c2;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  &__time {
    color: #97a1c2;
    font-size: 16px;
    min-width: 70px;
    display: flex;
    justify-content: center;
  }

  &__music-icon {
    margin: 0 10px;
    cursor: pointer;
  }

  &__rules {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      text-decoration-color: #5f6881;
    }
  }

  &__rules-text {
    margin-left: 5px;
    color: #5f6881;
  }
}

@media screen and (max-width: 576px) {
  .header {
    width: 100%;
    padding: 0 20px 0 15px;
    &__title {
      position: absolute;
      transform: translateY(48px);
      padding-left: 10px;
      font-size: 22px;
      z-index: 1001;
    }
    &__currencies {
      padding: 0 10px;
      min-width: 180px;
      background-color: unset;
    }
    &__balance {
      margin-right: 5px;
      font-size: 14px;
    }
    &__currency {
      margin-left: 5px;
      font-size: 12px;
    }
    &__currency-arrow {
      margin-left: 5px;
      margin-right: auto;
    }
    &__rules-text {
      display: none;
    }
  }
}

@keyframes opacityBalance {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
    transform: scale(1)
  }
  50% {
    transform: scale(1.6)
  }
  85% {
    opacity: 1;
    transform: scale(1)
  }
  100% {
    opacity: 0;
  }
}