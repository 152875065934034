@import url("https://fonts.googleapis.com/css?family=Roboto:100,400,700,900");

@font-face {
  font-family: "Proxima Nova Black";
  src: url("./services/fonts/ofont.ru_Proxima Nova.ttf");
}

@font-face {
  font-family: "Proxima Nova Bold";
  src: url("./services/fonts/ProximaNova-Bold.woff2");
}

@font-face {
  font-family: "Proxima Nova Semibold";
  src: url("./services/fonts/ProximaNova-Semibold.woff2");
}

@font-face {
  font-family: "Roboto-Black";
  src: url("./services/fonts/Roboto-Black.woff2");
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("./services/fonts/Roboto-Regular.woff2");
}

iframe {
  display: none;
}

.app {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  background: #0c121e;;
  color: #FFF;

  animation: appAnimation .1s linear forwards;

  &__header-layout {
    width: 100%;
    height: 4.2rem;
  }
}

#preloader {
  &::after {
    content: 'fiablegames.com';
    color: #FFF;
    position: absolute;
    padding-top: 4rem;
    text-transform: uppercase;
    font-size: .9rem;
  }
}

#root,
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Proxima Nova Bold";
  background: #0c121e;
}

html {
  font-size: 16px;
  @media screen and (min-width: 700px) {
    font-size: 16px;
  }
}

ul,
ol,
li,
p,
a,
h1,
h2,
h3,
h4,
h5,
div,
article,
button,
header,
h6 {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  color: inherit;
  user-select: none;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #2f364d;
  }
}

@media screen and (orientation:landscape) and (max-device-width: 900px) {
  html{
      -webkit-transform: rotate(-90deg);
         -moz-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
           -o-transform: rotate(-90deg);
              transform: rotate(-90deg);
      -webkit-transform-origin: left top;
         -moz-transform-origin: left top;
          -ms-transform-origin: left top;
           -o-transform-origin: left top;
              transform-origin: left top;
      width: 320px; /*Разрешение экрана iPhone*/
      position: absolute;
      top: 100%;
          left: 0
  }
}

@keyframes appAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}