.game {
  max-width: 120rem;
  height: calc(100% - 53px - 10px);
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  padding: 0 20px 10px 20px;
  &__left {
    width: calc(100% - 465px);
    flex-direction: column;
    justify-content: center;
    @media (min-width: 992px) and (max-width: 1199.98px) {
      width: calc(100% - 400px);
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
      width: calc(100% - 350px);
    }
  }
  &__right {
    width: 465px;
    padding-left: 15px;
    overflow: hidden;
    @media (min-width: 992px) and (max-width: 1199.98px) {
      width: 400px;
    }
    @media (min-width: 768px) and (max-width: 991.98px) {
      width: 350px;
    }
  }
  &__history-layout {
    height: calc(100% - 65px);
    width: 100%;
    transition: height .2s linear;
    &_isOpenChat {
      height: calc(100% - 315px);
    }
  }
  &__chat-layout {
    margin-top: 15px;
    padding: 0 20px;
    max-height: initial;
    transition: height .2s ease-in;
    height: 300px;
    background-color: #1f2533;
    border-radius: 5px;
    padding-bottom: 5px;
  }

  &__subheader-layout {
    width: 100%;
    height: 40px;
  }
  &__winning-layout {
    display: flex;
    justify-content: center;
  }
  &__game-layout {
    width: 100%;
    height: calc(100% - 40px - 125px - 15px);
    overflow: hidden;
    border-radius: 5px;
    margin-top: 2px;
    margin-bottom: 15px;
    

    @media (max-width: 991.98px) {
      background-color: unset;
      height: calc(14.4vw * 5 + 45px);
      margin-bottom: 0;
    }

    @media screen and (max-width: 765.98px) {
      margin-top: 0;
    }
  }
  &__basket-layout {
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .game {
    display: block;
    max-width: none;
    margin: 0;
    padding: 0 15px 15px 15px;
    &__left {
      width: 100%;
      margin: 0;
      background-color: unset;
      @media (min-width: 768px) and (max-width: 991.98px) {
        width: calc(100% - 350px);
      }
    }
    &__right {
      width: 100%;
      padding-left: 0;
      margin-top: 10px;
    }
    &__subheader-layout {
      border-bottom: unset;
      padding-right: 10px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(26, 34, 51, 0.4);
        border-radius: 5px;
        z-index: -1;
      }
    }
    &__chat-layout {
      display: none;
    }
    &__history-layout {
      height: 60vh;
    }
    &__basket-layout {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;
      height: initial;
    }
  }
}

@media screen and (min-width: 700px) {
  
}